import React, { useState,useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  Link,
  Avatar,
  Grid,
  CssBaseline,
  Alert,
  InputAdornment,
  IconButton,
  Checkbox
} from "@mui/material";
import { Google, AccountCircle, Password, CheckBox } from "@mui/icons-material";
import { auth, googleProvider, microsoftProvider, signInWithPopup } from "../firebase/firebaseConfig";
import { signInWithEmailAndPassword, sendEmailVerification, fetchSignInMethodsForEmail } from "firebase/auth";
import logo from "../assets/logo.jpg"; // Import the logo image
import productImage from "../assets/4.webp"; // Add an image related to your product
import "@fontsource/dm-sans"; // Import DM Sans
import GoogleIcon from "@mui/icons-material/Google"; // Example for Google
import MircrosoftIcon from "@mui/icons-material/Microsoft"; // Example for Facebook
import Divider from "@mui/material/Divider";
import { MailOutline } from "@mui/icons-material";
import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";


export default function Login() {
  const [showPassword,setShowPassword] = useState(false);
  const [rememberMe,setRememberMe] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showDisplayNameForm, setShowDisplayNameForm] = useState(false);
  const [newDisplayName, setNewDisplayName] = useState("");

  const successMessage = location.state?.message || "";

  useEffect(() => {
    const token = localStorage.getItem("jwttoken") || sessionStorage.getItem("jwttoken");
    if (token) {
      // Automatically log in the user and redirect to the dashboard
      navigate("/dashboard");
    }
  }, [navigate]); // The dependency array ensures this runs only once on mount


  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  }; 


  
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user.emailVerified) {
        const idToken = await user.getIdToken();
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/firebase-login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        });
        const json = await response.json();
        if (json.success) {
          if(rememberMe)
          {
            console.log("Inside storing in localstorage");
            localStorage.setItem("jwttoken", json.token || idToken);
            localStorage.setItem("user", json.displayName || user.displayName || "Anonymous");
            localStorage.setItem("email", json.email || user.email);
          }
          else
          {
            console.log("Inside storing in Sessionstorage");
            sessionStorage.setItem("jwttoken", json.token || idToken);
            sessionStorage.setItem("user", json.displayName || user.displayName || "Anonymous");
            sessionStorage.setItem("email", json.email || user.email);
          }
          navigate(`/dashboard`);
        } else {
          alert("Failed to log in");
        }
      } else {
        alert("Please verify your email before logging in.");
        if (window.confirm("Would you like us to resend the verification email?")) {
          await sendEmailVerification(user);
          alert("Verification email resent. Please check your inbox.");
        }
      }
    } catch (error) {
      if (error.code === "auth/too-many-requests") alert("Too many requests. Please try again later");
      else alert("Invalid Credentials");
    }
  };

  const handleOAuthSignIn = async (provider, providerName) => {
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const idToken = await user.getIdToken();
      console.log(process.env.REACT_APP_API_BASE_URL);

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/oauth-login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      });

      const json = await response.json();
      if (json.success) {
        if(rememberMe)
        {
          console.log("Inside localstorage Saving");
          localStorage.setItem("jwttoken", json.token);
          localStorage.setItem("user", user.displayName);
          localStorage.setItem("email", json.email);
        }
        else
        {
          console.log("Inside Sessionstorage Saving");
          sessionStorage.setItem("jwttoken", json.token);
          sessionStorage.setItem("user", user.displayName);
          sessionStorage.setItem("email", json.email);
        }
        const displayName = json.displayName;
        if (!displayName || displayName.trim() === "") {
          setShowDisplayNameForm(true);
        } else {
          navigate(`/dashboard`); 
        }
      } else {
        alert(`${providerName} Sign-In failed`);
      }
    } catch (error) {
      if (error.code === "auth/account-exists-with-different-credential") {
        const email = error.customData.email;
        const existingMethods = await fetchSignInMethodsForEmail(auth, email);

        if (existingMethods.includes("password")) {
          alert("An account with this email already exists. Please sign in with email and password.");
        } else if (existingMethods.includes("google.com")) {
          alert("An account with this email already exists. Please sign in with Google.");
        } else if (existingMethods.includes("microsoft.com")) {
          alert("An account with this email already exists. Please sign in with Microsoft.");
        } else {
          alert(`An account with this email already exists. Please sign in using one of the following methods: ${existingMethods.join(", ")}`);
        }
      } else {
        console.error(`Error during ${providerName} Sign-In`, error);
        alert(`${providerName} Sign-In failed`);
      }
    }
  };

  const handleDisplayNameSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/userdata/profile`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwttoken")}`,
      },
      body: JSON.stringify({ name: newDisplayName, companyname: "" }),
    });

    if (response.ok) {
      navigate(`/dashboard`);
    } else {
      alert("Failed to set display name.");
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        width: "100%",
        margin: 0,
      }}
    >
      <CssBaseline />
      
      {/* Left Side - Login Form */}
      <Box
        sx={{
          flex: 5,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          margin: 0,
          padding: 0,
        }}
      >
        <Card
          sx={{
            width: "460.87px",
            height: "100%",
            borderRadius: 0,
            boxShadow: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ffffff",
          }}
        >
          <Box
            sx={{
              width: "100%",
              // maxWidth: 450,
              padding: 4,
              backgroundColor: "#ffffff",
            }}
          >
            <Box display="flex" flexDirection="row" alignItems="center" mb={3} gap={2} lineHeight="12px">
              <Avatar src={logo} alt="Product Logo" sx={{ width: 64, height: 64, mb: 2 }} />
              <Typography
                variant="h4"
                component="h1"
                fontWeight={600}
                letterSpacing="2px"
                fontSize="20px"
                color="#CC9A39" // Muted navy blue to match Dashboard theme
                sx={{ fontFamily: "Poppins, sans-serif" }}
                lineHeight="12px"
                gutterBottom
              >
                Vavetek.AI
              </Typography>
            </Box>
            <Box
              sx={{
                display: "inline-block", 
                lineHeight: "44.4px",
                width: "100%",
                textAlign: "left"
              }}
            >
              <Typography
                component="span"
                variant="h5"
                sx={{
                  fontFamily: "DM Sans, sans-serif",
                  fontWeight: 700,
                  fontSize: "37px",
                  lineHeight: "44.4px",
                  color: "#000000", // Black color for "Log in to"
                }}
              >
                Log in to{" "}
              </Typography>
              <Typography
                component="span"
                variant="h5"
                sx={{
                  fontFamily: "DM Sans, sans-serif",
                  fontWeight: 700,
                  fontSize: "37px",
                  lineHeight: "44.4px",
                  color: "#CF9D3F", // Gold color for "VAVETEK.AI"
                }}
              >
                VAVETEK.AI
              </Typography>
            </Box>
            {successMessage && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {successMessage}
              </Alert>
            )}
            <Box 
              sx={{
                display: "flex", // Enables Flexbox
                alignItems: "center", // Vertically centers content
                justifyContent: "left", // Horizontally centers content (optional) 
                lineHeight: "27.2px",
                width: "100%",
                textAlign: "left",
                marginTop: "10px"
              }}>
              <Typography variant="body1" mb={3} sx=
              {{ 
                color: "#979797",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 400,
                fontSize: "17px",
                 
                }}>
                Welcome back! Select method to log in:
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between", // Ensures buttons are side by side
                gap: 2, // Adds space between buttons
                mt: 3, // Adds margin to the top
              }}
            >
              {/* Google Button */}
              <Button
                onClick={() => handleOAuthSignIn(googleProvider, "Google")}
                variant="outlined"
                size="large"
                startIcon={<GoogleIcon sx = {{color: "inherit"}}/>} // Google Icon
                sx={{
                  width:"222.24px",
                  fontWeight: 400,
                  fontSize:"17px",
                  fontFamily: "Poppins, sans-serif",
                  color: "#000000", // Google Blue
                  borderColor: "#D6D6D6", // Google Blue Border
                  borderRadius: "10px",
                  padding: "10px 20px", // Custom padding
                  "&:hover": {
                    borderColor: "#005cbf",
                    color: "#005cbf",
                  },
                }}
              >
                Google
              </Button>

              {/* Microsoft Button */}
              <Button
                onClick={() => handleOAuthSignIn(microsoftProvider, "Microsoft")}
                variant="outlined"
                size="large"
                startIcon={<MircrosoftIcon sx = {{color: "inherit"}}/>} // Google Icon
                sx={{
                  width:"222.24px",
                  fontWeight: 400,
                  fontSize:"17px",
                  fontFamily: "Poppins, sans-serif",
                  color: "#000000", // Google Blue
                  borderColor: "#D6D6D6", // Google Blue Border
                  borderRadius: "10px",
                  padding: "10px 20px", // Custom padding
                  "&:hover": {
                    borderColor: "#005cbf",
                    color: "#005cbf",
                  },
                }}
              >
                Microsoft
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 3, // Adds spacing between elements
                width: "100%", // Ensures full width
                my: 3, // Adds vertical margin
              }}
            >
              {/* Left Line */}
              <Divider sx={{ flex: 1, borderColor: "#D6D6D6", width: "129.43px" }} />

              {/* Middle Text */}
              <Typography
                sx={{
                  color: "#979797", // Gray color for the text
                  fontSize: "13px", // Adjust size as needed
                  fontFamily: "Poppins, sans-serif",
                  lineHeight: "20.8px",
                  fontWeight: 400,
                  letterSpacing: 0.3
                }}
              >
                or continue with email
              </Typography>

              {/* Right Line */}
              <Divider sx={{ flex: 1, borderColor: "#D6D6D6", width: "129.43px" }} />

            </Box>

            {!showDisplayNameForm ? (
              <Box component="form" onSubmit={handleSubmit}>
                <TextField
                  name="email" 
                  type="email"
                  variant="outlined"
                  placeholder="Email"
                  required
                  sx={{
                    width: "100%", // Match width
                    height: "55.74px", // Custom height
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px", // Rounded corners
                      borderColor: "#D6D6D6", // Border color
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "1px", // Border thickness
                      borderColor: "#D6D6D6", // Outline color
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#7d7d7d" }, // Label color
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutline sx={{ color: "#D3D3D3" }} /> 
                      </InputAdornment>
                    ),
                    style: {
                      backgroundColor: "#f8f9fa", // Input field background color
                    },
                  }}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  name="password" 
                  type={showPassword ? "text" : "password"} // Toggles between "text" and "password"
                  placeholder="Password"
                  variant="outlined"
                  required
                  sx={{
                    width: "100%", // Match width
                    height: "55.74px", // Custom height
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px", // Rounded corners
                      borderColor: "#D6D6D6", // Border color
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "1px", // Border thickness
                      borderColor: "#D6D6D6", // Outline color
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#7d7d7d" }, // Label color
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockOutlined sx={{ color: "#D3D3D3" }} /> 
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleTogglePasswordVisibility}
                          edge="end"
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? (
                            <VisibilityOff sx={{ color: "#D3D3D3" }} />
                          ) : (
                            <Visibility sx={{ color: "#D3D3D3" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      backgroundColor: "#f8f9fa", // Input field background color
                    },
                  }}
                  
                />

                <Box 
                  mt={2}  
                  display="flex"
                  flexDirection="row"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center" // Ensures vertical alignment
                  >
                   <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center" // Ensures vertical alignment
                    >
                    <Checkbox
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                      sx={{
                        color: "#333",
                        "&.Mui-checked": {
                          color: "#000",
                        },
                      }}
                    />
                    <Typography
                      component="span"
                      sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontWeight:500,
                        fontSize: "15px",
                        color: "#000000",
                        lineHeight: "24px"
                      }}
                    > 
                    Remember Me 
                    </Typography>
                  </Box>

                  <Link href="/forgotpassword" color="secondary" variant="body2" underline="hover">
                    <Typography
                        component="span"
                        sx={{
                          fontFamily: "Poppins, sans-serif",
                          fontWeight:500,
                          fontSize: "15px",
                          color: "#3352EC",
                          lineHeight: "24px"
                        }}
                      > 
                      Forgot password?
                    </Typography>
                  </Link>
                </Box>

                <Box mt={4} textAlign="center">
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      width: "100%",
                      height: "58.07px", 
                      background: "#CC9A39",
                      borderRadius: "10px",
                      boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                      "&:hover": {
                        boxShadow: "0px 12px 20px rgba(0, 0, 0, 0.2)",
                      },
                    }}
                  >
                    <Typography sx={{
                      fontFamily: "Poppins, sans-serif",
                      fontWeight: "600",
                      fontSize: "20px",
                      
                    }}>
                      Log in
                    </Typography>
                  </Button>
                </Box>


                <Box mt={4} textAlign="center">
                  <Typography variant="body2" sx={{ fontFamily: "Poppins, sans-serif", fontSize: "15px" ,fontWeight: 500,lineHeight: "24px"}}>
                  <span style={{ marginRight: "20px" }}>Don't have an account?</span>
                    <Link href="/register" color="primary" underline="hover">
                      Create an account
                    </Link>
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box component="form" onSubmit={handleDisplayNameSubmit}>
                <Typography variant="h6" color="primary" mb={2} sx={{ fontFamily: "Poppins, sans-serif" }}>
                  Please enter a display name to complete setup
                </Typography>
                <TextField
                  fullWidth
                  margin="normal"
                  name="displayName"
                  label="Display Name"
                  variant="outlined"
                  value={newDisplayName}
                  onChange={(e) => setNewDisplayName(e.target.value)}
                  required
                  InputProps={{
                    style: { backgroundColor: "#f8f9fa" },
                  }}
                />
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    mt: 2,
                    fontWeight: "bold",
                    fontFamily: "Poppins, sans-serif",
                    background: "linear-gradient(to right, #6b73ff, #000dff)",
                    color: "#ffffff",
                    borderRadius: 3,
                    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
                    "&:hover": {
                      background: "linear-gradient(to right, #000dff, #6b73ff)",
                      boxShadow: "0px 12px 20px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  Set Display Name
                </Button>
              </Box>
            )}
          </Box>
        </Card>
      </Box>

      {/* Right Side - Product Image */}
      <Box
        sx={{
          flex: 5,
          background: `url(${productImage}) center center/cover no-repeat`,
          height: "100vh",
        }}
      />
    </Box>
  );
}
