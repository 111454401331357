// src/components/SettingItem.js
import React from 'react';
import { Box, Typography, Switch } from '@mui/material';

const SettingItem = ({ label, isEnabled, onToggle }) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" mb={1} >
    <Typography variant="body1" sx={{fontFamily:"Poppins, sans-serif"}}>{label}</Typography>
    <Switch checked={isEnabled} onChange={onToggle} />
  </Box>
);

export default SettingItem;
