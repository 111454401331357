import React, { useState } from 'react';
import {
  Box,
  CssBaseline,
  Container,
  Toolbar,
  Typography,
  TextField,
  Button,
} from '@mui/material';
import Navbar from '../../components/Navbar';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const TransferTokens = () => {
  const [recipientEmail, setRecipientEmail] = useState('');
  const [amount, setAmount] = useState('');
  const [otp, setOtp] = useState(''); // OTP field
  const [loading, setLoading] = useState(false);
  const [otpRequested, setOtpRequested] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const requestOtp = async () => {
    setLoading(true);
    setError(null);

    try {
      const jwt = localStorage.getItem("jwttoken");

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/tokentransfer/request-otp`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to request OTP. Please try again.');
      }

      setOtpRequested(true); // OTP requested successfully
      setSuccess('OTP sent to your registered email.');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTransfer = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const jwt = localStorage.getItem("jwttoken") || sessionStorage.getItem("jwttoken");

      // Generate a unique transaction ID
      const transactionId = uuidv4();

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/tokentransfer/transfer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ recipientEmail, amount, transactionId, otp }), // Include OTP in request
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || 'Transfer failed. Please try again.');
      }

      // Navigate to the dashboard with a success message
      navigate('/dashboard', { state: { successMessage: 'Transfer Successful!' } });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: '#E5E5E5' }}>
        <Navbar />
        <Toolbar />
        <Container maxWidth="sm" sx={{ py: 4, backgroundColor: '#ffffff', borderRadius:"10px", boxShadow: 'none',marginTop:'100px' }}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{
              fontWeight: 600,
              fontSize:"24px",
              color: '#2C2A2A', 
              fontFamily: 'Poppins, sans-serif', 
              textTransform: 'uppercase',
              mb: 3,

            }}
          >
            Transfer Tokens
          </Typography>
          {error && (
            <Typography
              variant="body1"
              color="error"
              align="center"
              sx={{
                mb: 2,
                fontFamily: 'Poppins, sans-serif',
                fontWeight: '500',
                color: '#d32f2f', // Red for error text
              }}
            >
              {error}
            </Typography>
          )}
          {success && (
            <Typography
              variant="body1"
              color="success"
              align="center"
              sx={{
                mb: 2,
                fontFamily: 'Poppins, sans-serif',
                fontWeight: '500',
                color: '#388e3c', // Green for success text
              }}
            >
              {success}
            </Typography>
          )}
          <TextField
            label="Recipient's Email"
            fullWidth
            margin="normal"
            value={recipientEmail}
            onChange={(e) => setRecipientEmail(e.target.value)}
            sx={{ 
              backgroundColor: '#fff9ed', 
              borderRadius: '8px', // Adjust the radius as needed
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px', // Apply rounded corners to the input field
  },
            }} // Light background to visually separate input fields
          />
          <TextField
            label="Amount"
            type="number"
            fullWidth
            margin="normal"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            sx={{ 
              backgroundColor: '#fff9ed', 
              borderRadius: '8px', // Adjust the radius as needed
              '& .MuiOutlinedInput-root': {
                borderRadius: '8px', // Apply rounded corners to the input field
  },
            }} // Consistent input field color
          />
          {otpRequested && (
            <TextField
              label="Enter OTP"
              type="number"
              fullWidth
              margin="normal"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              sx={{ 
                backgroundColor: '#fff9ed', 
                borderRadius: '8px', // Adjust the radius as needed
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px', // Apply rounded corners to the input field
    },
              }} // Consistent input field color
            />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            {!otpRequested ? (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#CC9A39', // Soft blue for primary action
                  color: '#000000',
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: '600',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#ffffff', // Darker blue for hover effect
                  },
                }}
                onClick={requestOtp}
                disabled={loading}
              >
                {loading ? 'Requesting OTP...' : 'Request OTP'}
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={{
                  backgroundColor: '#0288d1', // Darker blue for confirm action
                  color: '#ffffff',
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: '600',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#0277bd', // Darker shade for hover effect
                  },
                }}
                onClick={handleTransfer}
                disabled={loading}
              >
                {loading ? 'Transferring...' : 'Confirm Transfer'}
              </Button>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default TransferTokens;
