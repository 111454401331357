import React from "react";
import Sidebar from "../../components/Sidebar.jsx";
import Navbar from "../../components/Navbar.jsx";
import { Box, Typography, Divider } from "@mui/material";
import { useParams } from 'react-router-dom';
import { Pages } from "@mui/icons-material";

function Tutorial() {
    const products = ["BAMROC","CRDR-BIM"]
    return (
    <Box display = "flex" id="tutorial-top-box" flex-direction = "column" background = "#E5E5E5" sx={{height:"100%",width:"100%"}}>
        <Box display="flex" sx={{background: "#E5E5E5" ,width:"100%", height : "100%", gap: 3}}>
            {products.map((product) => (
                <Box id = {product} display="flex" sx = {{
                background: "#ffffff",
                flex: 1,
                borderRadius:"10px",
                flexDirection: "column",
                height: "85%",
                padding: "30px",
                gap: 3
            
            }} >
                <Typography 
                    sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "25px",
                        // marginLeft:"30px",
                        // marginTop:"40px",
                        textAlign: "left",
                        fontWeight: "600"
                    }}>
                    {product} 
                </Typography>
                <Box display="flex" flexDirection="column" sx={{
                    gap:1
                }}>
                    <Typography 
                        sx={{
                            fontFamily: "Poppins, sans-serif",
                            fontSize: "20px",
                            // marginLeft:"30px", 
                            textAlign: "left",
                            fontWeight: "400",
                            color: "#706E6E"
                        }}>
                        {product} Tutorial
                    </Typography>
                    <Box display="flex" alignItems="flex-start" height="100%" >
                        <iframe 
                        width="100%" // Adjust width as needed
                        height="170%" // Adjust height as needed
                        src={product == "BAMROC"?"https://www.youtube.com/embed/VMrU91zMa3U":"https://www.youtube.com/embed/PEPham_Zjho"}
                        title="YouTube video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{
                            borderRadius: "10px",
                            marginTop: "10px",
                            border: "2px solid #706E6E",
                        }}
                    >

                    </iframe>
                    </Box>
                </Box>
            </Box>
            ))}
            {/* <Box display="flex" sx = {{
                background: "#ffffff",
                flex: 1,
                borderRadius:"10px",
                flexDirection: "column",
                gap: 3
            
            }} >
                <Typography 
                    sx={{
                        fontFamily: "Poppins, sans-serif",
                        fontSize: "25px",
                        marginLeft:"70px",
                        marginTop:"40px",
                        textAlign: "left",
                        fontWeight: "600"
                        
                    }}>
                    CRDR - BIM 
                </Typography>
                <Divider sx={{
                    marginLeft: "20px",
                    marginRight: "20px", 
                    borderColor: '#000', // Optional: Change color
                }}/> */}
            
                {/* <Box >
                    <iframe 
                    width="80%%" // Adjust width as needed
                    height="300%" // Adjust height as needed
                    src="https://www.youtube.com/embed/VMrU91zMa3U"
                    title="YouTube video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{
                        borderRadius: "10px",
                        marginTop: "10px"
                    }}
                >

                </iframe>
                </Box> */}
            {/* </Box> */}
        </Box>
    </Box>
    );
}

export default Tutorial;
