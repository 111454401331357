import "./App.css";
import { BrowserRouter as Router, Routes, Route,useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";
import Login from "./screens/Login";
import Register from "./screens/Register";
import Dashboard from "./screens/Dashboard/Dashboard";
import PluginLogin from "./screens/PluginLogin";
import ForgotPassword from "./screens/ForgotPassword";
import TransferTokens from "./screens/Dashboard/TransferTokens";
import { useEffect } from "react";
import Tutorial from "./screens/Dashboard/Tutorial";

function App() {
  
  return (
    <div className="App">
      <ThemeProvider theme={theme}> {/* Wrap ThemeProvider outside Router */}
        <Router>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/revitlogin" element={<PluginLogin />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/forgotpassword" element={<ForgotPassword />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/dashboard/add-token" element={<Dashboard />} />
              <Route exact path="/dashboard/transfer-tokens" element={<TransferTokens />} />
            <Route exact path="/tutorial/:name/:productName" element={<Tutorial />} />
            </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
