// src/views/SettingsView.js
import React, { useState } from 'react';
import { Card, CardContent, Typography, Box ,Divider} from '@mui/material';
import SettingItem from '../../components/SettingItem';

const SettingsView = () => {
  const [notificationsEnabled, setNotificationsEnabled] = useState(true);
  const [privacyPublic, setPrivacyPublic] = useState(false);

  return (
    <Card
      sx={{
        borderRadius: "10px",
        backgroundColor:"#ffffff",
        height:"247.61px",
        width:"100%",
        paddingLeft:3,
        paddingRight:3,
        paddingBottom:3,
        paddingTop: 2
      }}
    >
      <CardContent>
        <Box display="flex" justifyContent="space-between">
        <Typography variant="h5" sx={{ mb: 2 ,fontFamily: "Poppins,sans-serif",fontWeight:600,fontSize:"24px",lineHeight:"36px"}}>
          Settings Overview 
        </Typography>
        </Box>
        <Divider sx={{marginBottom:"20px"}}></Divider>
        <Box>
          <SettingItem
            label="Enable Notifications"
            isEnabled={notificationsEnabled}
            onToggle={() => setNotificationsEnabled(!notificationsEnabled)}
          />
          <SettingItem
            label="Public Profile"
            isEnabled={privacyPublic}
            onToggle={() => setPrivacyPublic(!privacyPublic)}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default SettingsView;
