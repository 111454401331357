import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import TokenIcon from '@mui/icons-material/Token';

const TokenCard = ({ tokens }) => (
  <Card
    sx={{
      borderRadius: 3,
      boxShadow: 'none', // Removed shadow for a flat design
      textAlign: 'center', 
      backgroundColor: '#ffffff', // Very light blue-gray for a soft, clean background
      height:"197.09px"
    }}
  >
    <CardContent sx = {{display:"flex",flexDirection:"row", height:"100%"}}>
      <Box display="flex" flexDirection="column" height="100%" justifyContent="space-between">
        <Typography
          // variant="h6"
          sx={{
            color: '#000000', // Muted navy blue for text color
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '600',
            fontSize: "24px",
            letterSpacing: '1px',
            height:'100%'
          }}
          gutterBottom
        >
          Available Tokens
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center" mt={3}>
          <TokenIcon sx={{ color: '#CC9A39', fontSize: 100}} /> {/* Soft blue for the icon */}
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              fontSize: "52px",
              color: '#CC9A39', // Muted navy blue for token count text
              fontFamily: 'Poppins, sans-serif',
              // ml: 1,
            }}
          >
            {tokens}
          </Typography>
        </Box>
      </Box>
    </CardContent>
    
  </Card>
);

export default TokenCard;
