import React from 'react';
import { Card, Typography, Avatar, Stack ,Box, Button,TextField} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import { useState, useEffect } from 'react';
import SaveIcon from '@mui/icons-material/Save';


const UserCard = ({ user }) => {
  const [isEditingPersonal,setIsEditingPersonal] = useState(false);
  const [isEditingAddress,setIsEditingAddress] = useState(false); 
  const [name,setName] = useState(user.name || ""); // Ensure we have a valid string
  const nameParts = name.split(" "); // Split the full name into parts
  const [personalDetails, setPersonalDetails] = useState({
    "First Name": user.name.split(" ")[0] || "",
    "Last Name": user.name.split(" ").slice(1).join(" ") || "",
    "Email Address": user.email || "",
    "Phone": user.phone || "",
  });
  const [addressDetails, setAddressDetails] = useState({
    "Country": user.country?user.country:"",
    "City/State": user.cityState?user.cityState:"",
    "Postal Code": user.postalCode?user.postalCode:"",
    "Company Name": user.company? user.company: "",
  });
  const handleChangePersonal = (key, value) => {
    setPersonalDetails((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleChangeAddress = (key, value) => {
    setAddressDetails((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const  handleEditingPersonal = async (key,value) => {
    // console.log("HH");
    if(isEditingPersonal)
    {
      try
      {
        console.log("Sending Data");
        const DataToBeSent = {
          name : personalDetails["First Name"] + " " + personalDetails["Last Name"],
          email: personalDetails["Email Address"], 
          phone: personalDetails["Phone"]
        }
        const token = localStorage.getItem("jwttoken") || sessionStorage.getItem("jwttoken"); 
        console.log();
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/userdata/firebase-update-personal`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(DataToBeSent),
          }
        );
        const json = await response.json();
        if (response.ok && json.success) {
          alert("Profile updated successfully!");
          // Optionally, update localStorage or sessionStorage with new details
          
          setName(json.updatedData.name);
          localStorage.setItem("user", json.updatedData.name || personalDetails.name || "Anonymous");
          localStorage.setItem("email", json.updatedData.email || personalDetails.email);
        } else {
          alert("Failed to update profile. Please try again.");
        }
      } catch (error) {
        console.error("Error updating profile:", error);
        alert("An error occurred while updating your profile. Please try again.");
      }
    }
    setIsEditingPersonal(!isEditingPersonal);
  }
  const handleEditingAddress = async (key,value) => {
    if(isEditingAddress)
    {
      try
      {
        // const DataToBeSent = {
          const country = addressDetails["Country"];
          const citystate = addressDetails["City/State"];
          const postalcode= addressDetails["Postal Code"];
          const companyname = addressDetails["Company Name"];
        // }
        const token = localStorage.getItem("jwttoken") || sessionStorage.getItem("jwttoken");  
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/userdata/firebase-update-address`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              country,
              citystate,
              postalcode,
              companyname
            }),
          }
        );
        const json = await response.json();
        if (response.ok && json.success) {
          alert("Profile updated successfully!");

          // Optionally, update localStorage or sessionStorage with new details
          localStorage.setItem("user", json.updatedData.name || addressDetails.name || "Anonymous");
          localStorage.setItem("email", json.updatedData.email || addressDetails.email);
        } else {
          alert("Failed to update profile. Please try again.");
        }
      } catch (error) {
        console.error("Error updating profile:", error);
        alert("An error occurred while updating your profile. Please try again.");
      }
      }
    setIsEditingAddress(!isEditingAddress);
  }


  return (
  <Box display="flex" flexDirection="column" sx={{
    backgroundColor: "#ffffff",
    height:"100vh",
    borderRadius:"10px"
  }}>
  <Card
    sx={{
      // flex:1,
      borderRadius: "10px 10px 0px 0px",
      boxShadow: 'none', // No shadow for a flat, minimal design
      padding: "60px",
      textAlign: 'center',
      background: 'linear-gradient(90deg,#DEF6FF 0%, #FCF4E4 100%)', // Soft blue to match the theme accent color
    }}
  >
      <Stack direction="column" alignItems="center" spacing={2} width="100%" height="100%">
        <Box display="flex" flexDirection="row" justifyContent="space-between" id="name-box" width="100%" height="100%">
        <Box sx={{flex:1}}>
        <Avatar
          // display="flex"

          sx={{
            display: 'flex',
            justifyContent: 'center', 
            background: 'linear-gradient(135deg, #FF6B6B 0%, #FFD93D 100%)', // Soft blue to match the theme accent color
            width: 120,
            height: 120,
            fontSize: 42, 
            color: '#ffffff' // White text inside avatar for readability
          }}
        >
          {name ? name[0].toUpperCase() : '?'}
        </Avatar>
        </Box>
        {/* <Box sx={{flexGrow:1}} /> */}
        <Box display= "flex" alignItems="center" marginBottom="42px" sx={{flex:6}}>
        <Typography
          display="flex"
          justifyContent="space-between"
          // alignItems= 'center'
          variant="h5"
          sx={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize:"34px",
            lineHeight:"36px",
            color: '#000000', // Muted navy blue for consistency with headings
            fontFamily: 'Poppins, sans-serif',
            mt:6,
            ml:2
          }}
        >
          {name}
        </Typography>
        </Box>
      </Box> 
        </Stack>
      </Card>
      <Box display="flex" flexDirection="column" sx={{
        border: "1px solid #D6D6D6", // 5px thick solid black border
        margin: "25px",
        borderRadius: "10px",
        paddingX: "25px",
        paddingTop: "15px",
        paddingBottom: "25px"
      }}>
        <Box display="flex" justifyContent={'space-between'}>
          <Typography
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "36px",
              color: "#000000"
            }}
          >
            Personal Information
          </Typography>
          <Button
            variant="outlined"
            startIcon={isEditingPersonal? <SaveIcon />: <EditIcon />}
            onClick={handleEditingPersonal}
            sx={{
              color: "#757575", // Gray color for text and icon
              borderColor: "#DADADA", // Light gray border
              textTransform: "none", // Prevent text capitalization
              borderRadius: "8px", // Rounded corners
              fontFamily: "Poppins, sans-serif",
              fontWeight: 500,
              fontSize: "14px",
              padding: "8px 16px", // Adjust padding for size
              "&:hover": {
                borderColor: "#BDBDBD", // Darker gray on hover
                backgroundColor: "#F5F5F5", // Light background on hover
              },
            }}
          >
            {isEditingPersonal?"Save":"Edit"}
          </Button>
        </Box>
        <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 3,
        }}
      >
        {Object.keys(personalDetails).map((key) => (
          <Box key={key}>
            <Typography textAlign = "left" variant="body2" sx={{ color: "#777676", fontWeight: 400, fontSize: "16px" , fontFamily: "Poppins, sans-serif"}}>
              {key}
            </Typography>
            {isEditingPersonal ? (
              <TextField
                fullWidth
                value={personalDetails[key]}
                onChange={(e) => handleChangePersonal(key, e.target.value)}
                size="small"
                variant="outlined"
              />
            ) : (
              <Typography variant="body1" textAlign="left" sx={{ color: "#000000",fontWeight: 500, fontSize: "16px", fontFamily: "Poppins, sans-serif"}}>{personalDetails[key]}</Typography>
            )}
          </Box>
        ))}
      </Box>
    </Box>
    <Box display="flex" flexDirection="column" sx={{
        border: "1px solid #D6D6D6", // 5px thick solid black border
        margin: "25px",
        borderRadius: "10px",
        paddingX: "25px",
        paddingTop: "15px",
        paddingBottom: "25px"
      }}>
        <Box display="flex" justifyContent={'space-between'}>
          <Typography
            sx={{
              fontFamily: "Poppins, sans-serif",
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "36px",
              color: "#000000"
            }}
          >
            Address
          </Typography>
          <Button
            variant="outlined"
            startIcon={isEditingAddress? <SaveIcon />: <EditIcon />}
            onClick={handleEditingAddress}
            sx={{
              color: "#757575", // Gray color for text and icon
              borderColor: "#DADADA", // Light gray border
              textTransform: "none", // Prevent text capitalization
              borderRadius: "8px", // Rounded corners
              fontFamily: "Poppins, sans-serif",
              fontWeight: 500,
              fontSize: "14px",
              padding: "8px 16px", // Adjust padding for size
              "&:hover": {
                borderColor: "#BDBDBD", // Darker gray on hover
                backgroundColor: "#F5F5F5", // Light background on hover
              },
            }}
          >
            {isEditingAddress?"Save":"Edit"}
          </Button>
        </Box>
        <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: 3,
        }}
      >
        {Object.keys(addressDetails).map((key) => (
          <Box key={key}>
            <Typography textAlign = "left" variant="body2" sx={{ color: "#777676", fontWeight: 400, fontSize: "16px" , fontFamily: "Poppins, sans-serif"}}>
              {key}
            </Typography>
            {isEditingAddress ? (
              <TextField
                fullWidth
                value={addressDetails[key]}
                onChange={(e) => handleChangeAddress(key, e.target.value)}
                size="small"
                variant="outlined"
              />
            ) : (
              <Typography variant="body1" textAlign="left" sx={{ color: "#000000",fontWeight: 500, fontSize: "16px", fontFamily: "Poppins, sans-serif"}}>{addressDetails[key]}</Typography>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  </Box>
);}

export default UserCard;
