import React from 'react';
import { useNavigate } from 'react-router-dom';
import TokenCard from '../../components/TokenCard';
import { Box, Button } from '@mui/material'; // Assuming you're using Material UI for styling
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'; // Example icon
import AddCircleIcon from '@mui/icons-material/AddCircle';

const TokensView = ({ userData }) => {
  const tokens = userData.bamrocTokens;
  const navigate = useNavigate();

  // Handlers for navigation
  const handleTransferTokens = () => {
    navigate('/dashboard/transfer-tokens'); // Adjust this path based on your routing setup
  };

  const handleAddTokens = () => {
    navigate('/dashboard/add-tokens'); // Adjust this path based on your routing setup
  };

  return (
    <Box
      sx={{
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between",
        backgroundColor: '#FFFFFF', // Light blue-gray background to match theme
        borderRadius: "10px",
        padding:"20px",
        paddingRight:"30px"
      }}
    >
      <TokenCard tokens={tokens} />
      <Box
        sx={{
          marginTop: 4,
          display: 'flex',
          gap: 2,
          justifyContent: 'center', // Center buttons to create a balanced layout
        }}
      >
        <Button
          variant="contained"
          startIcon={<SwapHorizIcon sx={{ color: '#777676' }} />} // Add the icon here
          sx={{
            backgroundColor: '#ffffff', // Soft blue for primary action
            color: '#777676', // White text for contrast
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '500',
            textTransform: 'none',
            border: "1px",
            fontSize:"16px",
            width:"223.77px",
            height:"52px",
            borderRadius:"10px",
            '&:hover': {
              backgroundColor: '#E0AD49', // Slightly darker blue for hover effect
            },
          }}
          onClick={handleTransferTokens}
        >
          Transfer Tokens
        </Button>
        <Button
          variant="contained"
          startIcon={<AddCircleIcon sx={{ color: '#777676' }} />}
          sx={{
            backgroundColor: '#E0AD49', // Different shade of blue for secondary action
            color: '#000000', // White text for contrast
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '500',
            fontSize:"16px",
            textTransform: 'none',
            width:"172.06px",
            height:"52px",
            borderRadius: "10px",
            '&:hover': {
              backgroundColor: '#ffffff', // Darker blue for hover effect
            },
          }}
          onClick={handleAddTokens}
        >
          Add Tokens
        </Button>
      </Box>
    </Box>
  );
};

export default TokensView;
