// src/styles/theme.js

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // Soft blue for buttons and primary elements
    },
    secondary: {
      main: "#9e9e9e", // Gray for secondary elements
    },
    background: {
      default: "#ffffff", // White background for minimalistic look
      paper: "#fcfffd", // Light gray for paper elements, like cards
    },
    text: {
      primary: "#333333", // Dark gray for main text
      secondary: "#666666", // Light gray for secondary text
    },
  },
  typography: {
    fontFamily: "'Roboto', Arial, sans-serif", // Clean, modern font
    h5: {
      fontWeight: 500, // Slightly bolder for headers
    },
    body2: {
      color: "#666666", // Light gray for secondary text
    },
    button: {
      textTransform: "none", // Keeps button text in normal case
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Rounded buttons
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: "#1976d2", // Blue avatar background to match primary color
        },
      },
    },
  },
});

export default theme;
