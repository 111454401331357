// src/views/ProfileView.js
import React from 'react';
import UserCard from '../../components/UserCard';
import { Phone } from '@mui/icons-material';

const ProfileView = ({userData}) => {
  const user = { 
    name: `${userData.displayName}`, 
    email: `${userData.email}`,  
    phone: `${userData.phone}`, 
    country: userData.country, 
    cityState: userData.citystate,
    postalCode: userData.postalcode,
    company: userData.companyName
  };


  return <UserCard user={user} />;
};

export default ProfileView;
