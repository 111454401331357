import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider
} from '@mui/material';

const CustomerSupportView = () => {
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null); // Holds the ticket selected for viewing details
  const [dialogOpen, setDialogOpen] = useState(false); // Controls the ticket detail dialog

  // Fetch tickets when component mounts
  useEffect(() => {
    async function fetchTickets() {
      try {
        const jwt = localStorage.getItem('jwttoken');

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/supportticket/list`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const json = await response.json();
        if (json.success) {
          setTickets(json.tickets);
        } else {
          setErrorMessage(json.message || 'Failed to fetch support tickets');
        }
      } catch (err) {
        setErrorMessage(err.message);
      } finally {
        setLoading(false);
      }
    }

    fetchTickets();
  }, []);

  // Handle ticket submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage(null);
    setErrorMessage(null);
    setLoadingSubmit(true);

    try {
      const jwt = localStorage.getItem('jwttoken');

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/supportticket/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
        body: JSON.stringify({ subject, description }),
      });

      const json = await response.json();
      if (response.ok) {
        setSuccessMessage('Your support ticket has been created successfully.');
        setSubject('');
        setDescription('');
        // Refetch tickets to show the newly created one
        setTickets([...tickets, json.ticket]);
      } else {
        setErrorMessage(json.message || 'An error occurred while submitting your support ticket.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while connecting to the server.');
    } finally {
      setLoadingSubmit(false);
    }
  };

  // Handle viewing ticket details
  const handleViewDetails = async (ticketId) => {
    try {
      setErrorMessage(null);
      setLoading(true);

      const jwt = localStorage.getItem('jwttoken');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/supportticket/${ticketId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const json = await response.json();
      setSelectedTicket(json);
      setDialogOpen(true); // Open the dialog to show ticket details
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
    <Box sx={{ mt: 4, backgroundColor: '#f7f7f7', p: 4, borderRadius: 2 }}>
      
      <Typography
      display="flex"
      justifyContent="space-between"
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 600,
          color: '#000000',
          fontSize: "24px",
          lineHeight: "36px",
          fontFamily: 'Poppins, sans-serif',  
          mb: 3,

        }}
      >
        Support Overview
      </Typography>
      <Divider sx={{ marginY: "30px"}}/>
      
      <Typography
        display="flex"
        justifyContent="space-between"
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: 500,
          fontSize:"20px",
          lineHeight: "36px",
          color: '#000000',
          fontFamily: 'Poppins, sans-serif', 
          mb: 3,
        }}
      >
        Raise an Issue - Customer Support
      </Typography>

      {/* Display success or error messages */}
      {successMessage && (
        <Alert
          severity="success"
          sx={{
            mb: 2,
            borderRadius: 2,
            backgroundColor: '#e0f7fa',
            color: '#006064',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '500',
          }}
        >
          {successMessage}
        </Alert>
      )}
      {errorMessage && (
        <Alert
          severity="error"
          sx={{
            mb: 2,
            borderRadius: 2,
            backgroundColor: '#ffcdd2',
            color: '#b71c1c',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '500',
          }}
        >
          {errorMessage}
        </Alert>
      )}

      {/* Support Ticket Form */}
      <Box component="form" onSubmit={handleSubmit} sx={{ mb: 4 }}>
        <TextField
          fullWidth
          label="Subject"
          variant="outlined"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          sx={{ mb: 2, '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
          required
        />
        <TextField
          fullWidth
          label="Description"
          variant="outlined"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ mb: 2, '& .MuiOutlinedInput-root': { borderRadius: 2 } }}
          required
        />
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: '#2C2A2A',
            color: '#ffffff',
            fontFamily: 'Poppins, sans-serif',
            fontWeight: '500',
            fontSize: "16px",
            lineHeight: "36px", 
            textTransform:"uppercase",
            marginTop:"15px",
            '&:hover': {
              backgroundColor: '#000000',
            },
          }}
          disabled={loadingSubmit}
        >
          {loadingSubmit ? 'Submitting...' : 'Submit Ticket'}
        </Button>
      </Box>
      </Box>
      <Box sx={{ mt: 4, backgroundColor: '#f7f7f7', p: 4, borderRadius: 2 }}>
      {/* Loading state for tickets */}
      {loading ? (
        <CircularProgress sx={{ color: '#5e92f3' }} />
      ) : (
        <>
          {/* Tickets List */}
          <Typography
            display="flex"
            justifyContent="space-between"
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: 500,
              color: '#000000',
              fontFamily: 'Poppins, sans-serif',
              fontSize: "20px",
              lineHeight:"36px",
              letterSpacing: '0.1em',
              textTransform: 'uppercase',
              mb: 2,
            }}
          >
            Your Support Tickets
          </Typography>
          {tickets.length === 0 ? (
            <Typography display="flex" justifyContent="space-between"sx={{ fontFamily: 'Poppins, sans-serif', color: '#000000' }}>
              No support tickets found.
            </Typography>
          ) : (
            <TableContainer component={Paper} sx={{ backgroundColor: '#ffffff', boxShadow: 'none', borderRadius: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        color: '#000000',
                        fontFamily: 'Poppins, sans-serif',
                        textTransform: 'uppercase',
                      }}
                    >
                      Ticket ID
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        color: '#000000',
                        fontFamily: 'Poppins, sans-serif',
                        textTransform: 'uppercase',
                      }}
                    >
                      Subject
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        color: '#000000',
                        fontFamily: 'Poppins, sans-serif',
                        textTransform: 'uppercase',
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        color: '#000000',
                        fontFamily: 'Poppins, sans-serif',
                        textTransform: 'uppercase',
                      }}
                    >
                      Created At
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        color: '#000000',
                        fontFamily: 'Poppins, sans-serif',
                        textTransform: 'uppercase',
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tickets.map((ticket) => (
                    <TableRow key={ticket.id} sx={{ '&:nth-of-type(even)': { backgroundColor: '#f0f4f8' } }}>
                      <TableCell
                        sx={{
                          fontFamily: 'Poppins, sans-serif',
                          color: '#607d8b',
                        }}
                      >
                        {ticket.id}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: 'Poppins, sans-serif',
                          color: '#607d8b',
                        }}
                      >
                        {ticket.subject}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: 'Poppins, sans-serif',
                          color: '#607d8b',
                        }}
                      >
                        {ticket.status}
                      </TableCell>
                      <TableCell
                        sx={{
                          fontFamily: 'Poppins, sans-serif',
                          color: '#607d8b',
                        }}
                      >
                        {new Date(ticket.createdAt).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          sx={{
                            color: '#3a506b',
                            borderColor: '#3a506b',
                            fontFamily: 'Poppins, sans-serif',
                            fontWeight: '500',
                            '&:hover': {
                              backgroundColor: '#e0f7fa',
                              borderColor: '#3a506b',
                            },
                          }}
                          onClick={() => handleViewDetails(ticket.id)}
                        >
                          View Details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      )}

      {/* Dialog for viewing ticket details */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 'bold',
            color: '#00000',
          }}
        >
          Ticket Details
        </DialogTitle>
        <DialogContent>
          {selectedTicket ? (
            <>
              <DialogContentText sx={{ fontFamily: 'Poppins, sans-serif', color: '#607d8b' }}>
                <strong>Subject:</strong> {selectedTicket.subject}
              </DialogContentText>
              <DialogContentText sx={{ fontFamily: 'Poppins, sans-serif', color: '#607d8b' }}>
                <strong>Description:</strong> {selectedTicket.description}
              </DialogContentText>
              <DialogContentText sx={{ fontFamily: 'Poppins, sans-serif', color: '#607d8b' }}>
                <strong>Status:</strong> {selectedTicket.status}
              </DialogContentText>
              <DialogContentText sx={{ fontFamily: 'Poppins, sans-serif', color: '#607d8b' }}>
                <strong>Created At:</strong> {new Date(selectedTicket.createdAt).toLocaleString()}
              </DialogContentText>
            </>
          ) : (
            <CircularProgress sx={{ color: '#5e92f3' }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            sx={{
              fontFamily: 'Poppins, sans-serif',
              color: '#3a506b',
              '&:hover': {
                backgroundColor: '#e0f7fa',
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      </Box>
    </Box>
  );
};

export default CustomerSupportView;
