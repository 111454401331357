import React from "react";
import { Box, Typography, Button, Grid , Paper } from "@mui/material";

function Products () {
    const products = [
        { id: 1, name: "BAMROC", description: "BAMROC is the ultimate solution for automating MEP clash resolution and removal in your BIM models. Its powerful AI engine detects, analyzes, and resolves MEP clashes at unparalleled speed, removing the manual burden of clash detection." },
        { id: 2, name: "CRDR", description: "CRDR BIM is a straightforward Navisworks plugin that streamlines the process of creating Clash Resolution Diagnosis Reports for Building Information Modeling (BIM) projects." },
      ];
    return (
        <Grid
      container
      spacing={3} // Adds spacing between grid items
      sx={{ padding: 2 }} // Adds padding around the grid
      
    >
      {products.map((product) => (
        <Grid
          key={product.id}
          item
          xs={6} // Full width on extra small screens
          sm={6} // 6 columns (50% width) on small screens
          md={6} // 4 columns (33.33% width) on medium screens
          lg={6} // 3 columns (25% width) on large screens
        
        >
          <Paper
            elevation={3}
            sx={{
              padding: 2, 
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
              minHeight: "277px",
              minWidth: "490px",
              textAlign: "left",
              gap: 3,
              display: "flex",
              flexDirection: "column",
              padding: "30px"
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontFamily: "Poppins, sans-serif", fontWeight: 600,fontSize: "25px", color: "#000000"}}
            >
              {product.name}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontFamily: "Poppins, sans-serif", color: "#706E6E",fontWeight: 400,fontSize: "14px", height:"85px" }}
            >
              {product.description}
            </Typography>
            <Box display="flex" flexDirection="row" justifyContent="space-around" sx={{
                gap: 2
            }}>
                <Button
                    variant="contained"   
                    sx={{
                    minWidth: "214px",
                    backgroundColor: "#ffffff",
                    color: "#979797", // Gray color for text and icon
                    borderColor: "#979797", // Light gray border
                    textTransform: "none", // Prevent text capitalization
                    borderRadius: "8px", // Rounded corners
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 500,
                    fontSize: "16px",
                    padding: "8px 16px", // Adjust padding for size
                    "&:hover": {
                        borderColor: "#BDBDBD", // Darker gray on hover
                        backgroundColor: "#CC9A39", // Light background on hover
                    },
                    }}
                >
                    Learn More
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    minWidth: "214px",
                    color: "#000000", // Gray color for text and icon
                    background: "#CC9A39",
                    textTransform: "none", // Prevent text capitalization
                    borderRadius: "8px", // Rounded corners
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 500,
                    fontSize: "14px",
                    padding: "8px 16px", // Adjust padding for size
                    "&:hover": {
                      borderColor: "#BDBDBD", // Darker gray on hover
                      backgroundColor: "#F5F5F5", // Light background on hover
                    },
                  }}
                  component="a" // Makes the Button render as an anchor tag
                  href={product.id == 1? "/assets/BAMROC_Setup.zip":""} // Path to the file in your public/assets folder
                  download // Forces the browser to download the file
                >
                  Download
                </Button>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
    );
}

export default Products;