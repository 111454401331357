// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, OAuthProvider } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyD53ZaFzlMrfNZosZmaHnteksHtnwmcAs4",
    authDomain: "bamroc-web-platform.firebaseapp.com",
    projectId: "bamroc-web-platform",
    storageBucket: "bamroc-web-platform.appspot.com",
    messagingSenderId: "358526342646",
    appId: "1:358526342646:web:5c8c68d1ad029f12ac63d7"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const microsoftProvider = new OAuthProvider('microsoft.com');

export { auth, googleProvider, signInWithPopup, db, microsoftProvider };
