import React, { useState } from 'react';
import { auth } from '../firebase/firebaseConfig'; // Adjust the import path as needed
import { sendPasswordResetEmail } from 'firebase/auth';
import { TextField, Button, Typography, Box, Container, Alert } from '@mui/material';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage('');
    setError('');

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset email sent! Please check your inbox.');
    } catch (error) {
      if (error.code === 'auth/user-not-found') {
        setError('No account found with this email.');
      } else if (error.code === 'auth/invalid-email') {
        setError('Please enter a valid email address.');
      } else {
        setError('Something went wrong. Please try again later.');
      }
    }
  };

  return (
    <Container maxWidth="sm" style={{ minHeight: "100vh", display: "flex", alignItems: "center" }}>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{ p: 4, backgroundColor: "background.paper", color: "text.primary", borderRadius: "12px", boxShadow: 3 }}
      >
        <Typography variant="h4" gutterBottom align="center" fontWeight="bold">
          Forgot Password
        </Typography>
        <Typography variant="body1" gutterBottom align="center" color="text.secondary">
          Enter your email to receive a password reset link
        </Typography>

        {message && <Alert severity="success" sx={{ my: 2 }}>{message}</Alert>}
        {error && <Alert severity="error" sx={{ my: 2 }}>{error}</Alert>}

        <TextField
          fullWidth
          margin="normal"
          name="email"
          label="Email"
          type="email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputLabelProps={{
            style: { color: "#666" },
          }}
          InputProps={{
            style: { backgroundColor: "#f5f5f5" },
          }}
          required
        />

        <Box mt={3} textAlign="center">
          <Button type="submit" variant="contained" color="primary" size="large">
            Send Reset Email
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
