import React from "react";
import { 
    Box,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper } from "@mui/material";
import { useState } from "react";


function Pricing (){
    const [offer,setOffer] = useState(-1);
    const offers = [
        {
        "RangeStart" : 500,
        "RangeEnd" : 999,
        "UnitPrice" : 0.47,
        "MinimumPrice": 235
        },
        {
            "RangeStart" : 1000,
            "RangeEnd" : 1499,
            "UnitPrice" : 0.3,
            "MinimumPrice": 375
            },
            {
                "RangeStart" : 1500,
                "RangeEnd" : 1999,
                "UnitPrice" : 0.25,
                "MinimumPrice": 375
                },
];
    const handleRowClick = (index) => {
        setOffer(index); // Update selected row index
      };
    return (
        <Box display="flex" flexDirection="column" sx={{
            background:"#FFFFFF",
            height: "100%",
            width: "100%",
            borderRadius: "10px",
            padding: "30px",
            gap: 2
        }}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{

            }}>
                <Typography sx={{
                    fontSize:"32px",
                    fontWeight:500,
                    fontFamily: "Poppins, sans-serif"
                }}>
                    Choose Your token Package
                </Typography>
                <Button sx={{
                    width: "214px",
                    height: "44px",
                    borderRadius: "8px",
                    background: "#CC9A39",
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "32px",
                    fontFamily: "Poppins, sans-serif",
                    '&:hover': {
                        borderRadius: '8px', // Maintain rounded corners on hover
                        background: "#B38633", // Slightly darker on hover
                        boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.15)",
                        animationDelay: "1s"
                      },
                }}>

                    Start Buying Tokens
                </Button>
            </Box>
            <Typography sx={{
                fontWeight: 400,
                fontStyle:"italic",
                fontSize: "18px",
                lineHeight: "18px",
                color: "#706E6E",
                textAlign: "left"
            }}>
                Affordable and Flexible Pricing for All Users
            </Typography>
            <Box display="flex" flexDirection="column" sx={{
                gap:1
            }}>
            <Typography sx={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "36px",
                color: "#777676",
                textAlign: "left"
            }}>
                Select Number of Tokens
            </Typography>
            <Box display="flex" flexDirection="row" sx={{
                gap:3
            }}>
                <Typography sx={{
                    display: "flex",
                    alignItems:"center",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: 400,
                    color: "#706E6E",
                    fontSize: "16px",
                    lineHeight: "14px",
                    border: "1px solid #979797", // Add border
                    borderRadius: "10px",        // Optional rounded corners
                    padding: "10px",           // Add space inside the border
                    width: "342px",
                    height: "51px"
                }}>
                    {offer == -1?"":`${offers[offer]["RangeStart"]} - ${offers[offer]["RangeEnd"]}`}
                </Typography>
                <Button onClick={() => {setOffer(-1)}} sx={{
                    width: "214px",
                    height: "44px",
                    borderRadius: "8px",
                    background: "#CC9A39",
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "32px",
                    fontFamily: "Poppins, sans-serif",
                    '&:hover': {
                        borderRadius: '8px', // Maintain rounded corners on hover
                        background: "#B38633", // Slightly darker on hover
                        boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.15)",
                        animationDelay: "1s"
                      },
                }}>
                    Clear Selection
                </Button>
            </Box>
        </Box>
        <TableContainer component={Paper} sx={{ borderRadius: "8px", borderColor:"#D6D6D6" }}>
      <Table sx={{ fontFamily: "Poppins, sans-serif", color: "#706E6E" }}>
        <TableHead>
          <TableRow borderB>
            <TableCell
              sx={{
                fontWeight: 500,
                backgroundColor: "#f5f5f5",
                fontFamily: "Poppins, sans-serif",
                color: "#706E6E",
                borderRight: "1px",
                borderRight: "1px solid #e0e0e0", // Column border
              }}
            >
              Tokens Range
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 500,
                backgroundColor: "#f5f5f5",
                fontFamily: "Poppins, sans-serif",
                color: "#706E6E",
                borderRight: "1px solid #e0e0e0", // Column border
              }}
            >
              Price per Token
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 500,
                backgroundColor: "#f5f5f5",
                fontFamily: "Poppins, sans-serif",
                color: "#706E6E",
                borderRight: "1px solid #e0e0e0", // Column border
              }}
            >
              Minimum Price
            </TableCell>
            <TableCell
              sx={{
                fontWeight: 500,
                backgroundColor: "#f5f5f5",
                fontFamily: "Poppins, sans-serif",
                color: "#706E6E",
                borderRight: "1px solid #e0e0e0", // Column border
              }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {offers.map((row, index) => (
            <TableRow
              key={index}
              onClick={() => handleRowClick(index)} // Handle row click
              sx={{
                cursor: "pointer", // Change cursor to pointer
                backgroundColor: offer === index ? "#fff8e1" : "inherit", // Highlight selected row
                "&:hover": {
                  backgroundColor: "#f5f5f5", // Slight hover effect
                },
              }}
            >
              <TableCell
                sx={{
                  fontWeight: offer === index ? 500 : 400,
                  color: offer === index ? "#cc9a39" : "#706E6E",
                  fontFamily: "Poppins, sans-serif",
                  borderRight: "1px solid #e0e0e0", // Column border
                }}
              >
                {row.RangeStart} - {row.RangeEnd}
              </TableCell>
              <TableCell
                sx={{
                fontWeight: offer === index ? 500 : 400,
                  color: offer === index ? "#cc9a39" : "#706E6E",
                  fontFamily: "Poppins, sans-serif",
                  borderRight: "1px solid #e0e0e0", // Column border
                }}
              >
                {row.UnitPrice}
              </TableCell>
              <TableCell
                sx={{
                fontWeight: offer === index ? 500 : 400,
                  color: offer === index ? "#cc9a39" : "#706E6E",
                  fontFamily: "Poppins, sans-serif",
                  borderRight: "1px solid #e0e0e0", // Column border
                }}
              >
                {row.MinimumPrice}
              </TableCell>
              <TableCell>
                <Typography
                  component="a"
                  href="#"
                  sx={{
                    color: "#007FFF",
                    fontWeight: 400,
                    textDecoration: "none",
                    fontFamily: "Poppins, sans-serif",
                    borderRight: "1px solid #e0e0e0", // Column border
                  }}
                >
                  Buy Now
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
    )
}

export default Pricing;